const constants = {
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  PIN_TOKEN: "pinToken",
  RESET_TOKEN: "resetToken",
  ACCOUNT_EMAIL: "accountEmail",
  PIN_LOGIN_NAME: "pinLoginName",
  X_MERCHANT_ID: "X-Merchant-ID",
  X_OUTLET_ID: "X-Outlet-ID",
  X_CLIENT_UUID: "X-Client-UUID",
  X_CLIENT_NAME: "restaurant-web",
  USER_EMAIL: "userEmail",

  SELECTED_BRAND_ID: "selectedBrandId",
  SELECTED_OUTLET_ID: "selectedOutletId",
  SELECTED_STOCKS_FILTERS: "selectedStocksFilters",

  POS_VERSION: "posVersion",
  POS_SELECTED_MODE: "posSelectedMode",

  ALL_X_OUTLET_ID: "all",
  LG_BREAKPOINT: 1024,
  CONFIG: {
    COUNTRY: "country",
    CURRENCY: "currency",
  },

  LAST_5_MERCHANTS: "last5Merchants",

  BILLING_PORTAL_URL:
    "https://billing.atlas.kitchen/p/login/4gw2aW8gm2Fea3e4gg",

  // Retool embed URLs
  RETOOL_SALES_REPORT_URL:
    "https://atlaskitchen.retool.com/embedded/public/8423601b-62d1-46ab-9916-76d857ad0767",
  RETOOL_SALES_REPORT_URL_BQ:
    "https://atlaskitchen.retool.com/embedded/public/95944c93-27d8-4a62-91a1-3b43fececa54",
  RETOOL_SALES_REPORT_URL_AI:
    "https://atlaskitchen.retool.com/embedded/public/362ddad7-c248-4a48-86eb-70610332b34c",
  RETOOL_DAILY_SUMMARY_REPORT_URL:
    "https://atlaskitchen.retool.com/embedded/public/a7eb32b4-6b9a-4ff7-92dc-809a8a205431",
  RETOOL_ADJUSTMENT_REPORT_URL:
    "https://atlaskitchen.retool.com/embedded/public/3e6ffa05-af98-4d9b-a6ea-e8433a1f980b",
  RETOOL_LOGISTICS_REPORT_NEW_URL:
    "https://atlaskitchen.retool.com/embedded/public/f70ed31c-6999-45fb-838c-b08414b2c1b9",
  RETOOL_PAYOUT_REPORT_URL:
    "https://atlaskitchen.retool.com/embedded/public/91d90c9d-f80a-4d12-be97-3657f4dd5f3a",
  RETOOL_AGGREGATOR_PROMOTIONS_MANAGER_URL:
    "https://atlaskitchen.retool.com/embedded/public/38622dd1-8c09-483b-b98c-0f06b7322f23",
  RETOOL_SALES_INSIGHTS_URL:
    "https://atlaskitchen.retool.com/embedded/public/c3b93d0d-af25-40c7-8eb2-cbb83d2da6c7",
  RETOOL_PRODUCT_INSIGHTS_URL:
    "https://atlaskitchen.retool.com/embedded/public/56622729-c2ed-4195-a7b6-1506156ee46d",
  RETOOL_PROMOTION_INSIGHTS_URL:
    "https://atlaskitchen.retool.com/embedded/public/0f6414cd-7c8a-4b42-9654-ca7a6f3e8d55",
  RETOOL_CUSTOMER_INSIGHTS_URL:
    "https://atlaskitchen.retool.com/embedded/public/9c8332f7-2567-4bb8-8e34-f94e896ef0a4",
  RETOOL_INTEGRATION_LOGS_DASHBOARD_URL:
    "https://atlaskitchen.retool.com/embedded/public/3aedb717-4097-4521-9fdd-1a9580fdec6b",
  RETOOL_LOGISTICS_MONITORING_URL: "https://atlaskitchen.retool.com/p/asl",
  RETOOL_BILLING_PORTAL_URL:
    "https://atlaskitchen.retool.com/p/stripe_billing_portal",

  STO_KIOSK_PRODUCTION_URL: "https://qr.atlas.kitchen",
  STO_KIOSK_DEVELOPMENT_URL: "https://qr-dev.atlas.kitchen",

  FEATURES: {
    USE_DISH_NOTES_ON_ITEM: "use_dish_notes_on_item",
    USE_DISH_NOTES_ON_ITEM_POS_OVERRIDE: "use_dish_notes_on_item_pos_override",
    RIDA_LOGISTICS: "use_rida_logistics",
    REFERRALS: "enable_referrals",
    DRIP_CAMPAIGNS: "enable_drip_campaigns",
    POS_BETA: "pos_beta",
    HIDE_POS: "hide_pos",
    HIDE_KDS: "hide_kds",
    ATLAS_CONNECT: "atlas_connect",
    DONATIONS: "donations",
    HIDE_CUTLERY_REQUIRED: "hide_cutlery_required",
    WYSIWYG_EDITOR: "wysiwyg_editor",
    SHOW_BRAND_SALES_SUMMARY: "show_brand_sales_summary",
    SKIP_PRINT_POS_RECEIPT_ON_CHECKOUT: "skip_print_pos_receipt_on_checkout",
    KITCHEN_DISPLAY_SYSTEM: "kitchen_display_system",
    POS_CHECKOUT_ADYEN_OVERRIDE: "pos_checkout_adyen_override",
    POS_TABLE_MANAGEMENT: "pos_table_management",
    POS_FLOORPLAN_LAYOUT: "pos_floorplan_layout",
    POS_PROMPT_CUSTOMER_DETAILS: "pos_prompt_customer_details",
    POS_CUSTOMER_NUMBER_OF_CHILDREN: "pos_customer_number_of_children",
    POS_AUTOMATIC_BUMP_TO_FLOORPLAN: "pos_automatic_bump_to_floorplan",
    POS_AUTOMATIC_LOGOUT: "pos_automatic_logout",
    POS_LOGOUT_AFTER_UNCHARGED_PRINT: "pos_logout_after_uncharged_print",
    WARNING_LOG_OUT_POS_WITH_UNSENT_ITEMS:
      "warning_log_out_pos_with_unsent_items",
    POS_DEFAULT_TO_TAKEAWAY: "pos_default_to_takeaway",
    ADD_NON_CONFIGURABLE_ITEMS_ON_TAP: "add_non_configurable_items_on_tap",
    SINGULAR_POS_ITEMS_ONLY: "singular_pos_items_only",
    FLOORPLAN_DEFAULT_POS_VIEW: "floorplan_default_pos_view",
    AGGREGATOR_PROMOTION_MAPPER: "aggregator_promotion_mapper",
    DISPLAY_ON_TIME_DELIVERIES_ON_FULFILMENT_REPORT:
      "display_on_time_deliveries_on_fulfilment_report",
    KDS_SEARCH: "kds_search",
    STATIONS: "use_stations",
    DEFER_POS_ORDER_CREATION: "defer_pos_order_creation",
    USE_SWAP_CASHIER_PRINT_ONLY_MODE: "use_swap_cashier_print_only_mode",
    SEND_TO_KITCHEN_FROM_POS: "send_to_kitchen_from_pos",
    ONLY_ENABLE_FIRE_TO_KITCHEN: "only_enable_fire_to_kitchen",
    POS_ALLOW_FIRE_BY_COURSE: "pos_allow_fire_by_course",
    RETOOL_SALES_INSIGHTS: "retool_sales_insights",
    RETOOL_PROMOTION_INSIGHTS: "retool_promotion_insights",
    RETOOL_CUSTOMER_INSIGHTS: "retool_customer_insights",
    CASH_REGISTER_ENABLED: "cash_register_enabled",
    USE_ORIGINAL_CART_ITEM_ORDER_FOR_KDS:
      "use_original_cart_item_order_for_kds",
    ALLOW_PRINT_UNCHARGED_CART: "allow_print_uncharged_cart",
    POS_ALLOW_OPEN_ITEM: "pos_allow_open_item",
    USE_PERMISSIONS_BASED_ACCESS: "use_permissions_based_access",
    NO_CONFIRMATION_ON_SEND_TO_KITCHEN: "no_confirmation_on_send_to_kitchen",
    POS_ALLOW_OPEN_DISCOUNT: "pos_allow_open_discount",
    SHOW_ITEM_NOTES_ON_DISPATCHER_PICKUP:
      "show_item_notes_on_dispatcher_pickup",
    USE_DINE_IN_OPERATING_HOURS: "use_dine_in_operating_hours",
    DISPATCHER_CALLER_SET_IFRAME: "dispatcher_caller_set_iframe",
    DISPATCHER_SHOW_CUSTOMER_NAME: "dispatcher_show_customer_name",
    KDS_SHOW_CUSTOMER_NAME: "kds_show_customer_name",
    SHOW_Z_REPORT: "show_z_report",
    SHOW_ADJUSTMENT_REPORT: "show_adjustment_report",
    REOPEN_POS_ORDER: "reopen_pos_order",
    POS_QUICK_LOGOUT: "pos_quick_logout",
    POS_LARGE_FONT_CART_FOOTER_BUTTONS: "pos_large_font_cart_footer_buttons",
    ALLOW_PRINTING_TO_SPECIFIC_STATION: "allow_printing_to_specific_station",
    LOAN_SHARK_MODE: "loan_shark_mode",
    ORDERS_SEARCH_FILTERS_ENABLED: "orders_search_filters_enabled",
    SHOW_NEW_PAYMENT_PROCESSORS: "show_new_payment_processors",
    USE_PAYMENT_PROCESSOR_FOR_TERMINALS: "use_payment_processor_for_terminals",
    RETOOL_USE_BIGQUERY: "retool_use_bigquery",
    USE_INVOICE_BASED_BILLING: "use_invoice_based_billing",
    POS_MULTI_DISPLAY_V2: "pos_multi_display_v2",
    DISABLE_AUDIO_POPUP_ON_AMP: "disable_audio_popup_on_amp",
    ENABLE_POS_OPEN_TAB_LIST_POLLING: "enable_pos_open_tab_list_polling",
    AI_PRODUCT_DESCRIPTION_GENERATOR: "ai_product_description_generator",
    AI_ANNOUNCEMENT_GENERATOR: "ai_announcement_generator",
    AI_CROSS_SELLING_GENERATOR: "ai_cross_selling_generator",
    AI_AUTO_COURSING: "ai_auto_coursing",
    AI_CUSTOMER_INSIGHTS: "ai_customer_insights",
    AI_REPORTS_INTELLIGENCE: "ai_reports_intelligence",
    LOYALTY: "loyalty_programs",
  },

  FULFILMENT_TYPE: {
    PICKUP: "pickup",
    DELIVERY: "delivery",
    DINE_IN: "dine_in",
  },

  FULFILMENT_SUB_TYPE: {
    IN_HOUSE: "in_house",
    EXTERNAL: "external",
  },

  PAYMENT_TERMINAL_STATUS: {
    PENDING: "pending",
    SUCCESS: "success",
    ERROR: "error",
    TIMEOUT: "timeout",
    DECLINED: "declined",
    NOT_SUPPORTED: "not_supported",
    NOT_ENOUGH_BALANCE: "not_enough_balance",
    CARD_EXPIRED: "card_expired",
    BUSY: "busy",
    CANCEL_ERROR: "cancel_error",
    CANCELLED: "cancelled",
    CANCELLED_BY_ISSUER: "cancelled_by_issuer",
  },

  ATLAS_PAY_ERRORS: {
    TIMEOUT: "Net::ReadTimeout with #<TCPSocket:(closed)",
    TRANSACTION_PENDING: "TRANSACTION%20IS%20PENDING",
  },

  ATLAS_PAY_ERROR_CODES: {
    BUSY: "busy",
    CANCELLED: "cancelled_by_issuer",
    CANCEL: "cancelled",
    DECLINED: "declined",
    CARD_EXPIRED: "card_expired",
    REFUSAL: "declined",
    NOT_SUPPORTED: "not_supported",
    NOT_ENOUGH_BALANCE: "not_enough_balance",
  },

  FULFILMENT_STATE: {
    UNASSIGNED: "unassigned",
    NOT_READY_FOR_PICKUP: "not_ready_for_pickup",
    ASSIGNING: "assigning",
    ASSIGNED: "assigned",
    DISPATCHED: "dispatched",
    READY_FOR_PICKUP: "ready_for_pickup",
    COMPLETED: "completed",
    CANCELLED: "cancelled",
  },

  FULFILMENT_SUB_STATE: {
    MATCHING_COURIER: "matching_courier",
    COURIER_NEAR_PICKUP: "courier_near_pickup",
    DELAYED: "delayed",
    COURIER_CANCELLED: "courier_cancelled",
  },

  FULFILMENT_STATE_TO_TAB_MAPPER: {
    unpaid: "unconfirmed",
    not_ready_for_pickup: "confirmed",
    unassigned: "confirmed",
    assigning: "confirmed",
    assigned: "confirmed",
    dispatched: "dispatched_completed_cancelled",
    ready_for_pickup: "confirmed",
    completed: "dispatched_completed_cancelled",
    cancelled: "dispatched_completed_cancelled",
    loading: "loading",
  },

  FULFILMENT_PAGES: {
    TODAY: "today",
    PREORDER: "preorder",
  },

  FULFILMENT_TABS: {
    CONFIRMED: "confirmed",
    UNCONFIRMED: "unconfirmed",
    DISPATCHED_COMPLETED_CANCELLED: "dispatched_completed_cancelled",
  },

  KIOSK_SPLASH_FULFILMENT_TYPES: {
    DINE_IN_AND_TAKEAWAY: "dine_in_and_takeaway",
    TAKEAWAY_ONLY: "takeaway_only",
    DINE_IN_ONLY: "dine_in_only",
  },

  VIEWING_MODE: {
    CARD: "card",
    ROW: "row",
  },

  CALLER_CARD_SIZE: {
    REGULAR: "regular",
    LARGE: "large",
    EXTRA_LARGE: "extraLarge",
  },

  CALLER_PREPARING_SECTION_SIZE: {
    HIDDEN: "hidden",
    SMALL: "small",
    LARGE: "large",
  },

  ORDER_STATES: {
    UNPAID: "unpaid",
    CONFIRMED: "confirmed",
    PREPARING: "preparing",
    DISPATCHED: "dispatched",
    READY_FOR_PICKUP: "ready_for_pickup",
    COMPLETED: "completed",
    CANCELLED: "cancelled",
  },

  ORDER_STATE_COLOR_MAP: {
    confirmed: "yellow",
    preparing: "orange",
    assigned: "blue",
    dispatched: "green",
    cancelled: "red",
  },

  SERVICES: {
    breakfast: 0,
    lunch: 1,
    offPeak: 2,
    happyHour: 3,
    dinner: 4,
    supper: 5,
  },

  ITEM_TYPES: {
    PRODUCT: "Product",
    COMPONENT: "Component",
    INVENTORY: "Inventory",
  },

  LOYALTY_TYPES: {
    CUSTOM_CURRENCY: {
      CASHBACK: "cashback",
      CUSTOM_POINT: "customPoint",
    },
    LOYALTY_EXPIRY_TYPES: {
      EXPIRES: "expires",
      DOES_NOT_EXPIRE: "doesNotExpire",
      DOES_NOT_EXPIRE_VALUE: -1,
    },
    CASHBACK: "CashbackProgram",
  },

  ANNOUNCEMENT_TYPES: {
    CHANNEL: "ChannelAnnouncement",
    STOREFRONT: "StorefrontAnnouncement",
  },

  BANNER_TYPES: {
    CHANNEL: "ChannelBanner",
    STOREFRONT: "StorefrontBanner",
  },

  POP_UP_TYPES: {
    CHANNEL: "ChannelPopup",
    STOREFRONT: "StorefrontPopup",
  },

  CONDITION_TYPES: {
    LEAD_TIME: "LeadTimeCondition",
  },

  PROMOTION_TYPES: {
    PROMOTION: "Promotion",
    REFERRAL: "ReferralPromotion",
    OPEN_DISCOUNT: "OpenDiscountPromotion",
  },

  PROMOTION_VALUE_TYPES: {
    FRACTIONAL: "fractional",
    PERCENT: "percent",
  },

  DISCOUNT_TYPES: {
    MERCHANT: "merchant",
    PLATFORM: "platform",
  },

  POS_COLORS: {
    BACKGROUND_COLOR: "#1A1C34", // atlas-navy-9
    FOREGROUND_COLOR: "#0D0E20", // atlas-navy-10
    TIMER_BACKGROUND_COLOR: "#F3F3FF", // atlas-navy-2
    BORDER_COLOR: "#585B85", // atlas-navy-6
    BADGE_BACKGROUND: "#1E2045", // atlas-navy-8
    ACTIVE_COLOR: "#4F46E5", // indigo-600
    DANGER_COLOR: "#DC2626", // atlas-red 600
    DANGER_LIGHT_COLOR: "#FEE2E2", // atlas-light-red
    BILLED_BADGE_COLOR: "#059669", // atlas-green-600
    BILLED_TABLE_COLOR: "#D1FAE5", // atlas-green-100
  },

  POS_TILE_TYPES: {
    GENERIC: "Pos::Tile",
    PAGE: "Pos::PageTile",
    ITEM: "Pos::ItemTile",
    PROMOTION: "Pos::PromotionTile",
    DONATION: "Pos::DonationTile",
  },

  COLORS: {
    ATLAS_PINK: "#4f46e5", // Use indigo-600 for now until we vet the atlas pink usage
  },

  CART_VIEW_MODES: {
    SIMPLE: "simple_view",
    DETAILED: "detailed_view",
  },

  CART_SORT_MODES: {
    OLDEST_FIRST: "oldest-first",
    NEWEST_FIRST: "newest-first",
    SPEND_LEAST_MOST: "spend-least-most",
    SPEND_MOST_LEAST: "spend-most-least",
  },

  WEBSOCKET_EVENTS: {
    JOINED: "joined",
    QUIT: "quit",
    ERROR: "error",
    READY: "ready",
  },

  POS_EVENTS: {
    CART_UPDATE: "cart_update",
    PING: "ping",
    PONG: "pong",
    DRAFT_CART_ITEM: "draft_cart_item",
    TERMINAL_PAYMENT: "terminal_payment",
  },

  DISPATCHER_EVENTS: {
    SCREEN_UPDATE: "screen_update",
    SET_IFRAME_URL: "set_iframe_url",
    CONFIG_CHANGED: "config_changed",
  },

  CALLER_THEMES: {
    dark: "dark",
    light: "light",
    black: "black",
  },

  POS_MODES: {
    CASHIER: "cashier",
    PRINT_ONLY: "print_only",
  },

  GRAB_FOOD: {
    CHARACTER_LIMIT: {
      SECTION: 60,
      ITEM: 80,
      ITEM_DESCRIPTION: 300,
      MODIFIER_GROUP: 50,
      MODIFIER: 40,
    },
  },

  SERVICE_TYPES: {
    BIKE: "BIKE",
    CAR: "CAR",
    MINIVAN: "MINIVAN",
  },

  CHANNELS: {
    ATLAS: "atlas",
    ATLAS_POS: "pos",
    ATLAS_ONLINE: "web",
    ATLAS_SCAN_TO_ORDER: "scan_to_order",
    ATLAS_KIOSK: "kiosk",
    GRAB_FOOD: "grab_food",
    FOODPANDA: "foodpanda",
    DELIVEROO: "deliveroo",
  },

  CHANNEL_TYPES: {
    WEBSITE: "Website",
    CHANNEL_APP: "ChannelApp",
    WALK_IN: "WalkIn",
  },

  ORDER_SOURCES: {
    IN_HOUSE: "in_house", // this is a source type
    POS: "pos",
    WEB: "web",
    SCAN_TO_ORDER: "scan_to_order",
    KIOSK: "kiosk",
    GRAB_FOOD: "grab_food",
    FOODPANDA: "foodpanda",
    DELIVEROO: "deliveroo",
  },

  PLATFORMS: {
    POS: "pos",
    SCAN_TO_ORDER: "scan_to_order",
    WEB: "web",
  },

  BRAND_TYPES: {
    BRAND: "Brand",
    STOREFRONT: "Storefront",
  },

  SALES_REPORT_TABS: {
    ORDERS: "orders",
    PAYMENT_TYPES: "payment_types",
    BRANDS: "brands",
  },

  MENU_SOURCES: {
    REVEL_LEGACY: "Revel",
    REVEL: "revel",
  },

  FILTERED_CREDENTIALS: "****FILTERED****",

  DISPLAY_DATETIME_FORMAT: "YYYY-MM-DD hh:mm A",
  DEFAULT_PER_PAGE: 30,
  DEFAULT_FULFILMENT_PER_PAGE: 50,
  DEFAULT_PER_PAGE_50: 50,

  STORED_PRIMARY_POS_UUID: "stored_primary_pos_uuid",
  CUSTOMER_FACING_DISPLAY_PATH: "/point-of-sale/secondary",

  Z_INDEX: {
    MENU_BUILDER: {
      PRODUCT_FORM_MODAL: 1000,
      SELECT_MODIFIER_GROUP_MODAL: 1000,
      MODIFIER_GROUP_FORM_MODAL: 1001,
      CHANGE_MODIFIER_GROUP_MODAL: 1002,
      MODIFIER_FORM_MODAL: 1002,
      MODIFIER_UPSELL_LABEL_MODAL: 1002,
      SELECT_MODIFIER_ITEM_MODAL: 1003,
      NESTED_PRODUCT_MODAL: 1004,
      SECTION_IN_OTHER_MENU_WARNING_MODAL: 2000,
      CONFIRM_DELETE_LABEL_MODAL: 2001,
    },
  },
  INTEGRATIONS: {
    DELIVEROO: "deliveroo",
    REVEL_MENU_SYNC: "revel-menu-sync",
  },
  MODAL_ACTIONS: {
    ADD: "add",
    EDIT: "edit",
    VIEW: "view",
  },
  CROSS_SELL_PRODUCT_TYPE: {
    TRIGGER: "triggering",
    PROMOTED: "promoted",
  },
  PAYMENT_TYPES: {
    ADYEN_PAYMENT_TYPE: "AdyenPaymentType",
    ADYEN_ECOMMERCE_PAYMENT_TYPE: "AdyenEcommercePaymentType",
  },
  ORDER_REFUND_TYPES: {
    ORDER: "OrderRefund",
    TIP: "OrderTipRefund",
  },

  ENTRY_TYPE_ORDER_DETAIL: "order_detail",

  PAYMENT_TYPE_TERMINAL_TYPES: {
    CREDIT_CARD: "credit_card",
    PAY_NOW: "paynow_pos",
    GRAB_PAY: "grabpay_pos",
    ALIPAY: "alipay",
    WECHAT_PAY: "wechatpay_pos",
  },
  ADYEN_SHOPPER_INTERACTION_TYPE: {
    POS: "POS",
    E_COMMERCE: "Ecommerce",
  },
  ADYEN_DEFAULT_CATEGORY_CODE: "5814",
  TABLE_ACTIONS: {
    EDIT: "edit",
    DRAW_ON_FLOOR_PLAN: "draw",
    REMOVE_FROM_FLOOR_PLAN: "remove_draw",
    DELETE: "delete",
    RESIZE: "resize",
  },
  POS_VIEW_MODES: {
    FLOOR_PLAN: "floor_plan",
    PRODUCTS_SELECTION: "products_selection",
  },
  PAYMENT_SPLIT_TYPES: {
    EVENLY: "evenly",
    MANUALLY: "manually",
    BY_ITEM: "byItem",
  },
  SPLITTED_BILL_TYPES: {
    VALUE: "ValueBill",
    ITEM: "ItemBill",
  },
  ATLAS_LOGISTICS_TYPE: [
    "asl_lalamove",
    "asl_milk_run",
    "asl_pandago",
    "asl_gogox",
  ],
  LOGISTICS_OPTIONS: {
    LALAMOVE: "lalamove",
    MILK_RUN: "milk_run",
    GOGOX: "gogox",
    PANDAGO: "pandago",
    ASL: "asl",
  },
  DENOMINATIONS: {
    DEFAULT: [0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 50, 100],
    // Other currencies an have their definition here and we can load/use them in cash predictions
  },
  SGD_DENOMINATIONS: [0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 50, 100],
  POS_CART_DISPLAY_MODES: {
    CART_ITEMS: "cart_items",
    MERGING_CARTS: "merging_cart",
    SPLITTING_CART: "splitting_cart",
    SENDING_ITEMS_TO_KITCHEN: "sending_items_to_kitchen",
    FIRING_BY_COURSE: "firing_by_course",
  },
  ACCOUNT_ROLES: {
    SERVER: "server",
    CASHIER: "cashier",
    MANAGER: "manager",
    FINANCE: "finance",
    ADMIN: "admin",
    SUPER_ADMIN: "super_admin",
  },

  AGGREGATOR_PAYMENT_LABEL: {
    grab_food: "GrabFood",
    foodpanda: "Foodpanda",
    deliveroo: "Deliveroo",
  },

  STATION_FEATURES: {
    POS_ENABLED: "posEnabled",
    CFD_ENABLED: "cfdEnabled",
    CASH_REGISTER_ENABLED: "cashRegisterEnabled",
    POS_PRINT_ONLY_ENABLED: "posPrintOnlyEnabled",
    KDS_ENABLED: "kdsEnabled",
    KIOSK_ENABLED: "kioskEnabled",
    DISPATCH_ENABLED: "dispatchEnabled",
    DISPATCH_CALLER_ENABLED: "dispatchCallerEnabled",
  },

  DINER_CONTACT_VISIBILITY_OPTIONS: {
    HIDDEN: "hidden",
    OPTIONAL: "optional",
    REQUIRED: "required",
  },

  NUTRI_GRADE: {
    A: "A",
    B: "B",
    C: "C",
    D: "D",
  },

  CREDIT_CARD_LABELS: [
    "mc",
    "visa",
    "amex",
    "jcb",
    "cup",
    "diners",
    "discover",
  ],
};

export default constants;
