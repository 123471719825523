import React, { useEffect, useRef, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import loadable from "@loadable/component";
import { isValidNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { message } from "@/common/Message";
import { UPDATE_PAYMENT_PROCESSOR_MUTATION } from "@/graphql/mutations/updatePaymentProcessor";
import { PAYMENT_PROCESSOR_QUERY } from "@/graphql/queries/getPaymentProcessor";
import constants from "@/utils/constants";
import handleSafariFormValidation from "@/utils/handleSafariFormValidation";

const Breadcrumbs = loadable(() => import("@/common/Breadcrumbs"));
const Button = loadable(() => import("@/common/Button"));
const Layout = loadable(() => import("@/common/Layout"));
const PaymentProcessorForm = loadable(() =>
  import("@/components/PaymentProcessors/PaymentProcessorForm"),
);
const Spin = loadable(() => import("@/common/Spin"));

export default function EditPaymentProcessor() {
  const { id } = useParams();
  const { t } = useTranslation();

  const formRef = useRef(null);
  const [form, setForm] = useState({});

  const paymentProcessorResponse = useQuery(PAYMENT_PROCESSOR_QUERY, {
    context: { graph: "restaurants" },
    variables: {
      id: parseInt(id),
    },
    onCompleted: (data) => {
      if (data?.paymentProcessor) {
        setForm(data.paymentProcessor);
      }
    },
  });

  const [updatePaymentProcessor, updatePaymentProcessorResponse] = useMutation(
    UPDATE_PAYMENT_PROCESSOR_MUTATION,
    {
      context: { graph: "restaurants" },
      errorPolicy: "all",
      onCompleted: (data) => {
        if (data?.updatePaymentProcessor) {
          setForm(data.updatePaymentProcessor);
          message.success(t("paymentProcessors.form.success.update"));
        }
      },
    },
  );

  useEffect(() => {
    if (paymentProcessorResponse?.error?.message) {
      message.error(paymentProcessorResponse?.error?.message);
    }
  }, [paymentProcessorResponse?.error?.message]);

  function getBreadcrumbs() {
    return [
      {
        label: t("navigation.admin.settings.paymentProcessors"),
        link: "/payment-processors",
      },
      form?.label || "",
    ];
  }

  return (
    <Layout
      className="edit-payment-method"
      pageTitle={t("paymentProcessors.header")}
    >
      <Spin
        spinning={
          paymentProcessorResponse?.loading ||
          updatePaymentProcessorResponse?.loading
        }
      >
        <div className="float-right">
          <Link to="/payment-processors">
            <Button className="ml-3">{t("common.cancel")}</Button>
          </Link>
          <Button
            className="ml-3"
            type="primary"
            onClick={() => {
              if (
                form.data?.payment_mobile_number &&
                !isValidNumber(form.data.payment_mobile_number)
              ) {
                message.error(t("paymentProcessors.form.error.contactNumber"));
                return;
              }
              if (!formRef.current.checkValidity()) {
                if (formRef.current.requestSubmit) {
                  formRef.current.requestSubmit();
                } else {
                  handleSafariFormValidation(formRef.current, t);
                }
              } else {
                const paymentProcessorForm = { ...form };
                if (
                  paymentProcessorForm.apiKey === constants.FILTERED_CREDENTIALS
                )
                  delete paymentProcessorForm.apiKey;
                if (
                  paymentProcessorForm.apiSecret ===
                  constants.FILTERED_CREDENTIALS
                )
                  delete paymentProcessorForm.apiSecret;

                updatePaymentProcessor({
                  variables: {
                    paymentProcessor: {
                      ...paymentProcessorForm,
                      outletPaymentProcessors:
                        paymentProcessorForm.outletPaymentProcessors?.map(
                          (outletPaymentProcessor) => ({
                            id: outletPaymentProcessor.isNew
                              ? null
                              : outletPaymentProcessor.id,
                            paymentProcessorId: parseInt(
                              paymentProcessorForm.id,
                            ),
                            outletId: parseInt(
                              outletPaymentProcessor.outlet.id,
                            ),
                            _destroy: outletPaymentProcessor._destroy ?? false,
                          }),
                        ) || [],
                    },
                  },
                });
              }
            }}
          >
            {t("common.save")}
          </Button>
        </div>
        <Breadcrumbs links={getBreadcrumbs()} />
        <PaymentProcessorForm
          form={form}
          setForm={setForm}
          formRef={formRef}
          error={updatePaymentProcessorResponse?.error?.message}
        />
      </Spin>
    </Layout>
  );
}
