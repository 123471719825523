import React, { useState, useRef, useEffect, useContext } from "react";

import loadable from "@loadable/component";
import PropTypes from "prop-types";
import qs from "qs";
import {
  Routes as Router,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import ConfigContext from "@/components/Config/configContext";
import useAnalytics from "@/hooks/useAnalytics";
import { getHasEditPermissions } from "@/utils/accessControl";
import constants from "@/utils/constants";
import getUrlParams from "@/utils/getUrlParams";

import EditPaymentProcessor from "./components/EditPaymentProcessor";
import PaymentProcessors from "./components/PaymentProcessors";
import RouteNotFound from "./components/RouteNotFound";
import { ROUTE_PATHS } from "./utils/routes";

const Account = loadable(() => import("./components/Account"));
const AdjustmentReasons = loadable(() =>
  import("./components/AdjustmentReasons"),
);
const AdjustmentReport = loadable(() =>
  import("./components/AdjustmentReport"),
);
const AdyenStores = loadable(() => import("./components/AdyenStores"));
const AggregatorPromotions = loadable(() =>
  import("./components/AggregatorPromotions"),
);
const AnnouncementFormScreen = loadable(() =>
  import(
    "./components/Announcements/AnnouncementFormScreen/AnnouncementFormScreen"
  ),
);
const AnnouncementsScreen = loadable(() =>
  import("./components/Announcements/AnnouncementsScreen/AnnouncementsScreen"),
);
const AreaFormScreen = loadable(() =>
  import("./components/Areas/AreaFormScreen"),
);
const AreasScreen = loadable(() => import("./components/Areas/AreasScreen"));
const BannerFormScreen = loadable(() =>
  import("./components/Banners/BannerFormScreen/BannerFormScreen"),
);
const BannersScreen = loadable(() =>
  import("./components/Banners/BannersScreen/BannersScreen"),
);
const Billing = loadable(() => import("./components/Billing"));
const Brands = loadable(() => import("./components/Brands"));
const CashVouchers = loadable(() => import("./components/CashVouchers"));
const ChannelLinks = loadable(() => import("./components/ChannelLinks"));
const Channels = loadable(() => import("./components/Channels"));
const CrossSellGroupFormScreen = loadable(() =>
  import("./components/CrossSellGroup/CrossSellGroupFormScreen"),
);
const CrossSellGroupsScreen = loadable(() =>
  import("./components/CrossSellGroup/CrossSellGroupsScreen"),
);
const Customers = loadable(() => import("./components/Customers"));
const CustomProviders = loadable(() => import("./CustomProviders"));
const CustomReport = loadable(() => import("./components/CustomReport"));
const DailySummaryReport = loadable(() =>
  import("./components/DailySummaryReport"),
);
const DeliveryFeeConfigs = loadable(() =>
  import("./components/DeliveryFeeConfigs"),
);
const DispatchCaller = loadable(() => import("./components/DispatchCaller"));
const DispatchControl = loadable(() => import("./components/DispatchControl"));
const Donations = loadable(() => import("./components/Donations"));
const DripCampaigns = loadable(() => import("./components/DripCampaigns"));
const EditBrand = loadable(() => import("./components/EditBrand"));
const EditChannelLink = loadable(() => import("./components/EditChannelLink"));
const EditDeliveryFeeConfig = loadable(() =>
  import("./components/EditDeliveryFeeConfig"),
);
const EditKitchenDisplays = loadable(() =>
  import("./components/EditKitchenDisplay"),
);
const EditLeadTimeCondition = loadable(() =>
  import("./components/EditLeadTimeCondition"),
);
const EditMenu = loadable(() => import("./components/EditMenu"));
const EditModifierGroup = loadable(() =>
  import("./components/EditModifierGroup"),
);
const EditOutlet = loadable(() => import("./components/EditOutlet"));
const EditPaymentMethod = loadable(() =>
  import("./components/EditPaymentMethod"),
);
const EditPaymentType = loadable(() => import("./components/EditPaymentType"));
const EditProduct = loadable(() => import("./components/EditProduct"));
const EditPromotion = loadable(() => import("./components/EditPromotion"));
const EditReferral = loadable(() => import("./components/EditReferral"));
const EditSection = loadable(() => import("./components/EditSection"));
const EditSurcharges = loadable(() => import("./components/EditSurcharges"));
const EnableAudioModal = loadable(() =>
  import("./components/EnableAudioModal"),
);
const Features = loadable(() => import("./components/Features"));
const ForgotPassword = loadable(() =>
  import("./components/ForgotPassword/ForgotPassword"),
);
const Fulfilment = loadable(() => import("./components/Fulfilment"));
const InsightDashboard = loadable(() =>
  import("./components/InsightDashboard"),
);
const InstalledApps = loadable(() => import("./components/InstalledApps"));
const IntegrationLogs = loadable(() => import("./components/IntegrationLogs"));
const KitchenDisplays = loadable(() => import("./components/KitchenDisplays"));
const KitchenDisplaySystem = loadable(() =>
  import("./components/KitchenDisplaySystem"),
);
const LeadTimeConditions = loadable(() =>
  import("./components/LeadTimeConditions"),
);
const Login = loadable(() => import("./components/Login"));
const LogisticsMonitoring = loadable(() =>
  import("./components/LogisticsMonitoring"),
);
const LogisticsReport = loadable(() => import("./components/LogisticsReport"));
const LogisticsReportNew = loadable(() =>
  import("./components/LogisticsReportNew"),
);

const LoyaltyProgramFormScreen = loadable(() =>
  import("./components/LoyaltyProgramFormScreen"),
);
const LoyaltyProgramScreen = loadable(() =>
  import("./components/LoyaltyProgramScreen"),
);
const Menus = loadable(() => import("./components/Menus"));
const Merchant = loadable(() => import("./components/Merchant"));
const MerchantChangedModal = loadable(() =>
  import("./common/Layout/MerchantChangedModal"),
);
const ModifierGroups = loadable(() => import("./components/ModifierGroups"));
const Orders = loadable(() => import("./components/Orders"));
const Outlets = loadable(() => import("./components/Outlets"));
const PaymentDiscrepancies = loadable(() =>
  import("./components/PaymentDiscrepancies"),
);
const PaymentMethods = loadable(() => import("./components/PaymentMethods"));
const PaymentTypes = loadable(() => import("./components/PaymentTypes"));
const PayoutReport = loadable(() => import("./components/PayoutReport"));
const Placeholder = loadable(() => import("./components/Placeholder"));
const PointOfSale = loadable(() => import("./components/PointOfSale"));
const PointOfSaleLogin = loadable(() =>
  import("./components/PointOfSale/PointOfSaleLogin"),
);
const PopUpFormScreen = loadable(() =>
  import("./components/PopUps/PopUpFormScreen/PopUpFormScreen"),
);
const PopUpsScreen = loadable(() =>
  import("./components/PopUps/PopUpsScreen/PopUpsScreen"),
);
const PosLayouts = loadable(() => import("./components/PosLayouts"));
const Products = loadable(() => import("./components/Products"));
const Profile = loadable(() => import("./components/Account/Profile"));
const Promotions = loadable(() => import("./components/Promotions"));
const Referrals = loadable(() => import("./components/Referrals"));
const ResetPassword = loadable(() =>
  import("./components/ResetPassword/ResetPassword"),
);
const SalesReportLoader = loadable(() =>
  import("./components/SalesReport/SalesReportLoader"),
);
const ScanToOrderChannelLinks = loadable(() =>
  import("./components/ScanToOrderChannelLinks"),
);
const Sections = loadable(() => import("./components/Sections"));
const Sidebar = loadable(() => import("./common/Layout/Sidebar"));
const Stations = loadable(() => import("./components/Stations"));
const Stocks = loadable(() => import("./components/Stocks"));
const Surcharges = loadable(() => import("./components/Surcharges"));
const TablesScreen = loadable(() => import("./components/Tables/TablesScreen"));
const Team = loadable(() => import("./components/Team"));

export default function Routes() {
  const [audioEnabled, setAudioEnabled] = useState(false);

  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [sidebarFirstLoad, setSidebarFirstLoad] = useState(true);

  const [isMerchantChanged, setMerchantChanged] = useState(false);

  const [features, setFeatures] = useState([]);

  const audioRef = useRef(null);
  const chimeRef = useRef(null);
  const location = useLocation();
  const hasEditPermissions = getHasEditPermissions();

  const showNewPaymentProcessorsEnabled = features?.includes(
    constants.FEATURES.SHOW_NEW_PAYMENT_PROCESSORS,
  );
  const showNewLogisticsReportsEnabled = features?.includes(
    constants.FEATURES.USE_INVOICE_BASED_BILLING,
  );

  const disableAudioPopupOnAmp = features?.includes(
    constants.FEATURES.DISABLE_AUDIO_POPUP_ON_AMP,
  );

  const { page } = useAnalytics();

  useEffect(() => {
    page(location.pathname, {
      merchant_identifier: localStorage.getItem("X-Merchant-ID") || "unknown",
    });
  }, [location.pathname]);

  useEffect(() => {
    const regex = /restaurant(-dev|-stg)*\.atlas\.kitchen/g;
    if (regex.test(location.origin)) {
      window.location.href = location.href.replace(
        regex,
        "portal$1.atlas.kitchen",
      );
    }
  }, []);

  useEffect(() => {
    // Set isNavigated to false on reload, back_forward and prerender
    if (window.performance) {
      if (location?.state) {
        location.state.isNavigated =
          window.performance.getEntriesByType("navigation")[0].type ===
          "navigate";
      }
    }
  }, [location]);

  const showEnableAudioModal = disableAudioPopupOnAmp
    ? ["dispatch-control", "dispatch-caller", "kitchen-display"].some(
        (path) =>
          location.pathname.includes(path) && !location.state?.isNavigated,
      )
    : !["/login", "/forgot-password", "/reset-password"].includes(
        location.pathname,
      );

  return (
    <CustomProviders
      audioEnabled={audioEnabled}
      audioRef={audioRef}
      chimeRef={chimeRef}
      sidebarVisible={sidebarVisible}
      setSidebarVisible={setSidebarVisible}
      sidebarFirstLoad={sidebarFirstLoad}
      setSidebarFirstLoad={setSidebarFirstLoad}
      setMerchantChanged={setMerchantChanged}
      setFeatures={setFeatures}
    >
      {showEnableAudioModal && (
        <EnableAudioModal
          audioEnabled={audioEnabled}
          audioRef={audioRef}
          chimeRef={chimeRef}
          setAudioEnabled={setAudioEnabled}
        />
      )}
      {isMerchantChanged && <MerchantChangedModal />}
      <div className="flex flex-row flex-1 h-full">
        <Sidebar
          sidebarVisible={sidebarVisible}
          setSidebarVisible={setSidebarVisible}
          sidebarFirstLoad={sidebarFirstLoad}
          setSidebarFirstLoad={setSidebarFirstLoad}
        />
        <Router>
          <Route
            path="/login"
            element={
              <Login audioRef={audioRef} setAudioEnabled={setAudioEnabled} />
            }
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            title="Reset password"
            path="/reset-password"
            element={<ResetPassword />}
          />
          <Route
            path="/"
            element={
              <ProtectedRoute element={<Navigate to="/fulfilment/today" />} />
            }
          />
          <Route
            path="/point-of-sale/login"
            element={<ProtectedRoute element={<PointOfSaleLogin />} />}
          />
          <Route
            path="/point-of-sale"
            element={<ProtectedRoute element={<PointOfSale />} />}
          />
          <Route
            path="/outlets/:outletId/stations/:stationId/point-of-sale/login"
            element={<ProtectedRoute element={<PointOfSaleLogin />} />}
          />
          <Route
            path="/outlets/:outletId/stations/:stationId/point-of-sale"
            element={<ProtectedRoute element={<PointOfSale />} />}
          />
          <Route
            path="/pos-layouts/:posLayoutId/edit"
            element={<ProtectedRoute element={<PointOfSale editing />} />}
          />
          <Route
            path={`/outlets/:outletId/stations/:stationId${constants.CUSTOMER_FACING_DISPLAY_PATH}`}
            element={
              <ProtectedRoute element={<PointOfSale secondaryDisplay />} />
            }
          />
          <Route
            path={constants.CUSTOMER_FACING_DISPLAY_PATH}
            element={
              <ProtectedRoute element={<PointOfSale secondaryDisplay />} />
            }
          />
          <Route
            path="/pos-layouts"
            element={<ProtectedRoute element={<PosLayouts />} />}
          />
          <Route
            path="/pos-layouts/new"
            element={<ProtectedRoute element={<PosLayouts />} />}
          />
          <Route
            path="/stations"
            element={<ProtectedRoute element={<Stations />} />}
          />
          <Route
            path="/fulfilment"
            element={
              <ProtectedRoute
                element={<Navigate to="/fulfilment/today" replace />}
              />
            }
          />
          <Route
            path="/fulfilment/today"
            element={
              <ProtectedRoute element={<Fulfilment selectedPage="today" />} />
            }
          />
          <Route
            path="/fulfilment/preorder"
            element={
              <ProtectedRoute
                element={<Fulfilment selectedPage="preorder" />}
              />
            }
          />
          <Route
            path="/fulfilment/unpaid"
            element={
              <ProtectedRoute element={<Fulfilment selectedPage="unpaid" />} />
            }
          />
          <Route
            path="/sales-report"
            element={<ProtectedRoute element={<SalesReportLoader />} />}
          />
          <Route
            path="/daily-summaries-report"
            element={<ProtectedRoute element={<DailySummaryReport />} />}
          />
          <Route
            path="/adjustments-report"
            element={<ProtectedRoute element={<AdjustmentReport />} />}
          />
          {/* Support old URLs */}
          <Route
            path="/fulfilment-report"
            element={<Navigate to="/logistics-report" replace />}
          />
          <Route
            path="/logistics-report"
            element={<ProtectedRoute element={<LogisticsReport />} />}
          />
          <Route
            path="/logistics-report-new"
            element={
              <ProtectedRoute
                element={
                  showNewLogisticsReportsEnabled ? (
                    <LogisticsReportNew />
                  ) : (
                    <LogisticsReport />
                  )
                }
              />
            }
          />
          <Route
            path="/custom-reports"
            element={<ProtectedRoute element={<CustomReport />} />}
          />
          {/* Support old URLs */}
          <Route
            path="/finance-report"
            element={<Navigate to="/payout-report" replace />}
          />
          <Route
            path="/payout-report"
            element={<ProtectedRoute element={<PayoutReport />} />}
          />
          <Route
            path="/account"
            element={<ProtectedRoute element={<Account />} />}
          >
            <Route
              path="/account"
              element={
                <ProtectedRoute
                  element={<Navigate to="/account/profile" replace />}
                />
              }
            />
            <Route
              path="profile"
              element={<ProtectedRoute element={<Profile />} />}
            />
          </Route>
          <Route
            title="Features"
            path="/features"
            element={<ProtectedRoute element={<Features />} permissions />}
          />
          <Route
            path="/insights/sales"
            element={
              <ProtectedRoute element={<InsightDashboard scope="sales" />} />
            }
          />
          <Route
            path="/insights/products"
            element={
              <ProtectedRoute element={<InsightDashboard scope="products" />} />
            }
          />
          <Route
            path="/insights/promotions"
            element={
              <ProtectedRoute
                element={<InsightDashboard scope="promotions" />}
              />
            }
          />
          <Route
            path="/insights/customers"
            element={
              <ProtectedRoute
                element={<InsightDashboard scope="customers" />}
              />
            }
          />
          <Route
            path="/menus"
            element={<ProtectedRoute element={<Menus />} />}
          />
          <Route
            path="/menus/new"
            element={<ProtectedRoute element={<Menus />} />}
          />
          <Route
            path="/menus/:id/edit"
            element={<ProtectedRoute element={<EditMenu />} />}
          />
          <Route
            path="/menus/:menu_id/sections/:id/edit"
            element={<ProtectedRoute element={<EditSection />} />}
          />
          <Route
            path="/sections"
            element={<ProtectedRoute element={<Sections />} />}
          />
          <Route
            path="/sections/new"
            element={<ProtectedRoute element={<Sections />} />}
          />
          <Route
            path="/sections/:id/edit"
            element={<ProtectedRoute element={<EditSection />} />}
          />
          <Route
            path="/sections/:section_id/sub_sections/:id/edit"
            element={<ProtectedRoute element={<EditSection />} />}
          />
          <Route
            path="/products"
            element={<ProtectedRoute element={<Products />} />}
          />
          <Route
            path="/products/new"
            element={<ProtectedRoute element={<Products />} />}
          />
          <Route
            path="/products/:id/edit"
            element={<ProtectedRoute element={<EditProduct />} />}
          />
          <Route
            path="/products/:product_id/item-modifier-groups/:id/edit"
            element={<ProtectedRoute element={<EditModifierGroup />} />}
          />
          <Route
            path="/modifier-groups"
            element={<ProtectedRoute element={<ModifierGroups />} />}
          />
          <Route
            path="/modifier-groups/new"
            element={<ProtectedRoute element={<ModifierGroups />} />}
          />
          <Route
            path="/modifier-groups/:id/edit"
            element={<ProtectedRoute element={<EditModifierGroup />} />}
          />
          <Route
            path="/lead-time-conditions"
            element={<ProtectedRoute element={<LeadTimeConditions />} />}
          />
          <Route
            path="/lead-time-conditions/new"
            element={<ProtectedRoute element={<LeadTimeConditions />} />}
          />
          <Route
            path="/lead-time-conditions/:id/edit"
            element={<ProtectedRoute element={<EditLeadTimeCondition />} />}
          />
          <Route
            path="/customers"
            element={<ProtectedRoute element={<Customers />} />}
          />
          <Route
            path="/customers/new"
            element={<ProtectedRoute element={<Customers />} />}
          />
          <Route
            path="/customers/:id/edit"
            element={<ProtectedRoute element={<Customers />} />}
          />
          <Route
            path="/orders"
            element={<ProtectedRoute element={<Orders />} />}
          />
          <Route
            path="/stocks"
            element={<ProtectedRoute element={<Stocks />} />}
          />
          <Route
            path="/promotions"
            element={<ProtectedRoute element={<Promotions />} />}
          />
          <Route
            path="/promotions/new"
            element={<ProtectedRoute element={<EditPromotion />} />}
          />
          <Route
            path="/promotions/:id/edit"
            element={<ProtectedRoute element={<EditPromotion />} />}
          />
          <Route
            path="/cash-vouchers"
            element={<ProtectedRoute element={<CashVouchers />} />}
          />
          <Route
            path="/cash-vouchers/new"
            element={<ProtectedRoute element={<CashVouchers />} />}
          />
          <Route
            path="/cash-vouchers/:id/edit"
            element={<ProtectedRoute element={<CashVouchers />} />}
          />
          <Route
            path="/donations"
            element={<ProtectedRoute element={<Donations />} permissions />}
          />
          <Route
            path="/integration-logs"
            element={<ProtectedRoute element={<IntegrationLogs />} />}
          />
          <Route
            path="/aggregator-promotions"
            element={<ProtectedRoute element={<AggregatorPromotions />} />}
          />
          <Route
            path="/referrals"
            element={<ProtectedRoute element={<Referrals />} />}
          />
          <Route
            path="/referrals/new"
            element={<ProtectedRoute element={<EditReferral />} />}
          />
          <Route
            path="/referrals/:id/edit"
            element={<ProtectedRoute element={<EditReferral />} />}
          />
          <Route
            path="/drip-campaigns"
            element={<ProtectedRoute element={<DripCampaigns />} />}
          />
          <Route path="/couriers" element={<Placeholder route="couriers" />} />
          <Route
            path="/outlets"
            element={<ProtectedRoute element={<Outlets />} />}
          />
          <Route
            path="/delivery-fee-configs"
            element={<ProtectedRoute element={<DeliveryFeeConfigs />} />}
          />
          <Route
            path="/surcharges"
            element={<ProtectedRoute element={<Surcharges />} />}
          />
          <Route
            path="/surcharges/new"
            element={<ProtectedRoute element={<Surcharges />} />}
          />
          <Route
            path="/surcharges/:id/edit"
            element={<ProtectedRoute element={<EditSurcharges />} />}
          />
          <Route
            path="/payment-processors-legacy"
            element={<ProtectedRoute element={<PaymentMethods />} />}
          />
          <Route
            path="/payment-processors-legacy/:id/edit"
            element={<ProtectedRoute element={<EditPaymentMethod />} />}
          />
          <Route
            path="/payment-processors"
            element={
              <ProtectedRoute
                element={
                  showNewPaymentProcessorsEnabled ? (
                    <PaymentProcessors />
                  ) : (
                    <PaymentMethods />
                  )
                }
              />
            }
          />
          <Route
            path="/payment-processors/:id/edit"
            element={
              <ProtectedRoute
                element={
                  showNewPaymentProcessorsEnabled ? (
                    <EditPaymentProcessor />
                  ) : (
                    <EditPaymentMethod />
                  )
                }
              />
            }
          />
          <Route
            path="/outlets/new"
            element={<ProtectedRoute element={<Outlets />} permissions />}
          />
          <Route
            path="/outlets/:id/edit"
            element={<ProtectedRoute element={<EditOutlet />} />}
          />
          <Route
            path="/delivery-fee-configs"
            element={
              <ProtectedRoute element={<DeliveryFeeConfigs />} permissions />
            }
          />
          <Route
            path="/delivery-fee-configs/:id/edit"
            element={<ProtectedRoute element={<EditDeliveryFeeConfig />} />}
          />
          <Route
            path="/outlets/:id/delivery-fee-configs/new"
            element={<ProtectedRoute element={<EditOutlet />} />}
          />
          <Route
            path="/outlets/:id/stations/:stationId/edit"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/outlets/:id/stations/new"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/outlets/:id/stations/:stationId/printers/:printerId/edit"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/outlets/:id/stations/:stationId/printers/new"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/outlets/:id/stations/:stationId/kiosk"
            element={<ProtectedRoute element={<Stations />} />}
          />
          <Route
            path="/outlets/:id/stations/:stationId/kiosk/:kioskId/edit"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/outlets/:id/stations/:stationId/kiosk/new"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/outlets/:id/stations/:stationId/dispatcher/:dispatchConfigId/edit"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/outlets/:id/stations/:stationId/payment-terminals/:paymentTerminalId/edit"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/outlets/:id/stations/:stationId/payment-terminals/new"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/outlets/:id/printers/:printerId/edit"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/outlets/:id/printers/new"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/delivery-fee-configs/new"
            element={
              <ProtectedRoute element={<DeliveryFeeConfigs />} permissions />
            }
          />
          <Route
            path="/payment-processors-legacy/new"
            element={
              <ProtectedRoute element={<PaymentMethods />} permissions />
            }
          />
          <Route
            path="/outlets/:id/payment-processors-legacy/new"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/payment-processors/new"
            element={
              <ProtectedRoute
                element={
                  showNewPaymentProcessorsEnabled ? (
                    <PaymentProcessors />
                  ) : (
                    <PaymentMethods />
                  )
                }
                permissions
              />
            }
          />
          <Route
            path="/outlets/:id/payment-processors/new"
            element={<ProtectedRoute element={<EditOutlet />} permissions />}
          />
          <Route
            path="/brands"
            element={<ProtectedRoute element={<Brands />} />}
          />
          <Route
            path="/brands/new"
            element={<ProtectedRoute element={<Brands />} permissions />}
          />
          <Route
            path="/brands/:id/edit"
            element={<ProtectedRoute element={<EditBrand />} permissions />}
          />
          {hasEditPermissions && (
            <Route
              path="/merchant"
              element={<ProtectedRoute element={<Merchant />} permissions />}
            />
          )}
          <Route
            path="/channels"
            element={<ProtectedRoute element={<Channels />} permissions />}
          />
          <Route
            path="/channels/new"
            element={<ProtectedRoute element={<Channels />} permissions />}
          />
          <Route
            path="/channels/:id/edit"
            element={<ProtectedRoute element={<Channels />} permissions />}
          />
          <Route
            path="/channel-links"
            element={<ProtectedRoute element={<ChannelLinks />} permissions />}
          />
          <Route
            path="/channel-links/new"
            element={<ProtectedRoute element={<ChannelLinks />} permissions />}
          />
          <Route
            path="/channel-links/:id/edit"
            element={
              <ProtectedRoute element={<EditChannelLink />} permissions />
            }
          />
          {/* Adyen stores */}
          <Route
            path="/adyen-stores"
            element={<ProtectedRoute element={<AdyenStores />} permissions />}
          />
          <Route
            path="/adyen-stores/new"
            element={<ProtectedRoute element={<AdyenStores />} permissions />}
          />
          <Route
            path="/scan-to-order"
            element={<ProtectedRoute element={<ScanToOrderChannelLinks />} />}
          />
          <Route
            path="/scan-to-order/new"
            element={<ProtectedRoute element={<ScanToOrderChannelLinks />} />}
          />
          <Route
            path="/scan-to-order/:id/edit"
            element={<ProtectedRoute element={<EditChannelLink />} />}
          />
          <Route
            path="/kitchen-displays"
            element={<ProtectedRoute element={<KitchenDisplays />} />}
          />
          <Route
            path="/kitchen-displays/new"
            element={<ProtectedRoute element={<KitchenDisplays />} />}
          />
          <Route
            path="/kitchen-displays/:id/edit"
            element={<ProtectedRoute element={<EditKitchenDisplays />} />}
          />
          <Route
            path="/kitchen-display-system"
            element={<ProtectedRoute element={<KitchenDisplaySystem />} />}
          />
          <Route
            path="/kitchen-display-system/:id"
            element={<ProtectedRoute element={<KitchenDisplaySystem />} />}
          />
          <Route
            path="/outlets/:outletId/stations/:stationId/kitchen-display-system/:id"
            element={<ProtectedRoute element={<KitchenDisplaySystem />} />}
          />
          <Route
            path="/outlets/:outletId/stations/:stationId/dispatch-control"
            element={<ProtectedRoute element={<DispatchControl />} />}
          />
          <Route
            path="/outlets/:outletId/stations/:stationId/dispatch-caller"
            element={<ProtectedRoute element={<DispatchCaller />} />}
          />
          <Route
            path="/payment-types"
            element={<ProtectedRoute element={<PaymentTypes />} />}
          />
          <Route
            path="/payment-types/new"
            element={<ProtectedRoute element={<PaymentTypes />} />}
          />
          <Route
            path="/payment-types/:id/edit"
            element={<ProtectedRoute element={<EditPaymentType />} />}
          />
          <Route
            path="/adjustment-reasons"
            element={<ProtectedRoute element={<AdjustmentReasons />} />}
          />
          <Route
            path="/adjustment-reasons/new"
            element={<ProtectedRoute element={<AdjustmentReasons />} />}
          />
          <Route
            path="/adjustment-reasons/:id/edit"
            element={<ProtectedRoute element={<AdjustmentReasons />} />}
          />
          <Route path="/team" element={<ProtectedRoute element={<Team />} />} />
          <Route
            path="/team/new"
            element={<ProtectedRoute element={<Team />} />}
          />
          <Route
            path="/team/:merchant_account_id/edit"
            element={<ProtectedRoute element={<Team />} />}
          />
          <Route
            path="/team/:merchant_account_id/outlets/new"
            element={<ProtectedRoute element={<Team />} />}
          />
          <Route
            path="/team/:merchant_account_id/outlets/:id/edit"
            element={<ProtectedRoute element={<Team />} />}
          />
          <Route
            path="/team/:merchant_account_id/pin-code"
            element={<ProtectedRoute element={<Team />} />}
          />
          <Route
            path="/apps-installed"
            element={<ProtectedRoute element={<InstalledApps />} />}
          />
          <Route
            path="/billing"
            element={<ProtectedRoute element={<Billing />} />}
          />
          {/* Announcements */}
          <Route path={ROUTE_PATHS.ANNOUNCEMENTS}>
            <Route
              index
              element={<ProtectedRoute element={<AnnouncementsScreen />} />}
            ></Route>

            <Route
              path={ROUTE_PATHS.COMMONS.CREATE}
              element={<ProtectedRoute element={<AnnouncementFormScreen />} />}
            />
            <Route
              path={ROUTE_PATHS.COMMONS.EDIT}
              element={<ProtectedRoute element={<AnnouncementFormScreen />} />}
            />
          </Route>
          {/* Pop-ups */}
          <Route path={ROUTE_PATHS.POP_UPS}>
            <Route
              index
              element={<ProtectedRoute element={<PopUpsScreen />} />}
            ></Route>

            <Route
              path={ROUTE_PATHS.COMMONS.CREATE}
              element={<ProtectedRoute element={<PopUpFormScreen />} />}
            />
            <Route
              path={ROUTE_PATHS.COMMONS.EDIT}
              element={<ProtectedRoute element={<PopUpFormScreen />} />}
            />
          </Route>
          {/* Banners */}
          <Route path={ROUTE_PATHS.BANNERS}>
            <Route
              index
              element={<ProtectedRoute element={<BannersScreen />} />}
            />

            <Route
              path={ROUTE_PATHS.COMMONS.CREATE}
              element={<ProtectedRoute element={<BannerFormScreen />} />}
            />
            <Route
              path={ROUTE_PATHS.COMMONS.EDIT}
              element={<ProtectedRoute element={<BannerFormScreen />} />}
            />
          </Route>
          {/* Cross sell groups */}
          <Route path={ROUTE_PATHS.CROSS_SELL_GROUPS}>
            <Route
              index
              element={<ProtectedRoute element={<CrossSellGroupsScreen />} />}
            />

            <Route
              path={ROUTE_PATHS.COMMONS.CREATE}
              element={
                <ProtectedRoute element={<CrossSellGroupFormScreen />} />
              }
            />
            <Route
              path={ROUTE_PATHS.COMMONS.EDIT}
              element={
                <ProtectedRoute element={<CrossSellGroupFormScreen />} />
              }
            />
          </Route>
          {/* Loyalty Program */}
          <Route path={ROUTE_PATHS.LOYALTY}>
            <Route
              index
              element={<ProtectedRoute element={<LoyaltyProgramScreen />} />}
            />

            <Route
              path={ROUTE_PATHS.COMMONS.CREATE}
              element={
                <ProtectedRoute element={<LoyaltyProgramFormScreen />} />
              }
            />
            <Route
              path={ROUTE_PATHS.COMMONS.EDIT}
              element={
                <ProtectedRoute element={<LoyaltyProgramFormScreen />} />
              }
            />
          </Route>
          {/* Tables */}
          <Route
            path={ROUTE_PATHS.TABLES}
            element={<ProtectedRoute element={<TablesScreen />} />}
          />
          {/* Areas */}
          <Route path={ROUTE_PATHS.AREAS}>
            <Route
              index
              element={<ProtectedRoute element={<AreasScreen />} />}
            />

            <Route
              path={ROUTE_PATHS.COMMONS.EDIT}
              element={<ProtectedRoute element={<AreaFormScreen />} />}
            />
          </Route>
          {/* Payment discrepancies */}
          {hasEditPermissions && (
            <Route
              path="/payment-discrepancies"
              element={
                <ProtectedRoute
                  element={<PaymentDiscrepancies />}
                  permissions
                />
              }
            />
          )}
          {/* Atlas internal retool apps */}
          {hasEditPermissions && (
            <Route
              path="/logistics-monitoring"
              element={
                <ProtectedRoute element={<LogisticsMonitoring />} permissions />
              }
            />
          )}
          <Route
            path="*"
            element={<ProtectedRoute element={<RouteNotFound />} />}
          />
        </Router>
      </div>
    </CustomProviders>
  );
}

ProtectedRoute.propTypes = {
  element: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  permissions: PropTypes.bool,
};

function ProtectedRoute({ element, permissions = false }) {
  const location = useLocation();

  const { configQuery } = useContext(ConfigContext);
  const isPermissionsEnabled = configQuery?.config?.features?.includes(
    constants.FEATURES.USE_PERMISSIONS_BASED_ACCESS,
  );
  // check for login before checking for whether route exists
  if (
    !localStorage.getItem(constants.ACCESS_TOKEN) &&
    location.pathname !== "/login"
  ) {
    return (
      <Navigate
        to={{
          pathname: "/login",
          search: qs.stringify({
            ...getUrlParams(location),
            redirect: location.pathname + location.search,
          }),
        }}
      />
    );
  }

  if (isPermissionsEnabled) {
    // TODO: Do route level checks
  } else {
    // TODO: implement scalable way to grant access control
    const hasEditPermissions = getHasEditPermissions();
    if (permissions && !hasEditPermissions) {
      return (
        <Navigate
          to={{
            pathname: "/not-found",
            search: qs.stringify({
              ...getUrlParams(location),
              redirect: location.pathname + location.search,
            }),
          }}
        />
      );
    }
  }

  return <>{element}</>;
}
